.breadcrumb {
    background-color: aliceblue !important;
  }
  


  .about-container {
    background-size: cover;
  }
  
  
  .about-card {
    border: none;
    border-radius: 15px;
  }
  
  .about-title {
  }
  
  .about-text {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
  